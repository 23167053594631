.app {
  height: 100vh;
  width: 100vw;
  background-image: url("./assets/pondbg.png");
  background-size: cover;
  overflow: auto;
}

.container {
  padding: 15px;
  display: flex;
  flex-direction: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.authed-container {
  display: flex;
  flex-direction: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100vw;
  margin: 5px;
}

.header {
  display: flex;
}

body {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 20px;
}

.connected-container {
  display: flex;
  justify-content: center;
  align-items: top;
  align-content: center;
  color: white;
  width: 500px;
  height: 580px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.45);
  border: solid 3px black;
  border-radius: 10px;
}

.game-container {
  background-color: rgba(0, 0, 0, 0.45);
  border: solid 10px black;
}

.connected-container button {
  display: flex;
  justify-content: center;
  height: 50px;
  width: 100px;
}

.sub-text {
  font-size: 20px;
  font-weight: 800;
  color: whitesmoke;
  filter: drop-shadow(3px 3px 2px black);
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button-container {
  display: flex;
  justify-content: center;
}

.cta-button {
  height: 45px;
  border: 0;
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
}

.connect-wallet-button {
  background: -webkit-linear-gradient(left, #60c657, #35aee2);
  background-size: cover;
  animation: gradient-animation 4s ease infinite;
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100vw;
  margin-top: 45px;
  padding-bottom: 25px;
}

.twitter-logo {
  width: 35px;
  height: 35px;
  margin: 5px;
  filter: drop-shadow(0px 5px px black);
}

.footer-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.Powered-by-Rocketsauce {
  width: 250px;
  filter: drop-shadow(0px 5px 3px black);
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.duckgame-logo {
  display: flex;
  align-content: center;
  top: 10px;
  filter: drop-shadow(0px 5px 8px black);
}

.dapper-logo {
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-content: left;
  justify-content: center;
  margin: 20px;
}

.game {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.game-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 500px;
  height: 580px;
}

.weblinks {
  font-size: 20px;
  color: white;
}

.weblinks a {
  font-size: 18px;
  font-weight: 800;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  color: inherit;
}

.links-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-items: center;
  margin: 15px;
}

.weblinks span {
  margin: 10px;
}
